<template>
    <v-container fluid>
        <v-card flat class="px-3 mt-1 overflow-y-auto" :height="height">
            <v-row no-gutters v-if="allRegisteredKiosks.length !== 0">
                <v-col :cols="kioskListDimensions.cols" v-for="(kiosk, i) in allRegisteredKiosks" :key="i">
                    <v-card flat class="pa-1 my-3" :class="getCardClass(kiosk.data().onlineStatus)"
                        width="95%">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-row class="fill-height" no-gutters>
                                    <v-col cols="10">
                                        <span class="font-weight-bold ml-2">{{ kiosk.data().name }}</span>
                                        <br>
                                        <!-- <span class="text-caption">Environment: {{ kiosk.data().env }}</span>
                                        <br> -->
                                        <v-chip x-small class="ma-2">
                                            <span class="text-caption">{{ kiosk.data().applicationId }}</span>
                                        </v-chip>
                                    </v-col>
                                    <v-col cols="2" align-self="end" align="end">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon medium color="primary" @click="viewAnalytics(kiosk)"
                                                    v-bind="attrs" class="ma-1" v-on="on">
                                                    mdi-google-analytics
                                                </v-icon>


                                            </template>
                                            <span>View Analytics</span> <!-- Tooltip content -->
                                        </v-tooltip>
                                    </v-col>
                                    <!-- <v-btn outlined color="primary" @click="viewAnalytics(kiosk)" small class="mt-2">
                                        
                                        Analytics</v-btn> -->
                                    <!-- <v-col cols="12" align-self="end" v-if="kiosk.data().configured">
                                        <v-btn outlined color="primary" @click="viewAnalytics(kiosk)" small
                                            class="mt-4">
                                            <v-icon left color="primary">
                                                mdi-google-analytics
                                            </v-icon>
                                            Analytics</v-btn>
                                    </v-col> -->
                                    <!-- <v-col v-else>
                                        <span>Kiosk not configured</span>
                                    </v-col> -->
                                </v-row>
                            </v-col>
                            <!-- <v-col cols="3" v-if="kiosk.data().avatarInfo !== undefined">
                                <v-row no-gutters>
                                    <v-col cols="12">
                                        <v-img :src=getImage(kiosk.data()) contain height="100"></v-img>
                                    </v-col>
                                    <v-col cols="12" align="center" class="text-caption font-weight-bold">
                                        <span>{{ kiosk.data().avatarInfo.avatarName }}</span>
                                    </v-col>
                                </v-row>
                            </v-col> -->
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-else no-gutters align="center">
                <v-card width="90%" flat>
                    <v-card-text class="font-weight-bold">
                        No Registered kiosks
                    </v-card-text>
                </v-card>
            </v-row>
        </v-card>
    </v-container>
</template>
<script>
import { color } from 'd3';
import { mapGetters } from 'vuex'
export default {
    name: "kioskList",
    data() {
        return {
            height: window.innerHeight / 3.3
        }
    },
    computed: {
        ...mapGetters(['allRegisteredKiosks', 'avatarList', 'kioskListDimensions'])
    },
    methods: {
        getColor(status) {
            if (status === "Unavailable") return "error";
            if (status === "Busy") return "amber";
            if (status === "Available") return "success";
        },
        getCardClass(status) {
            if (status === "Unavailable") return "cardBorderOffline";
            if (status === "Busy") return "cardBorderBusy";
            if (status === "Available") return "cardBorderOnline";
        },
        getCardColor(status) {
            console.log('Status at get Card color: ', status)
            if (status === "Available") return "green lighten-4";
        },
        getImage(avatar) {
            let temp = this.avatarList.filter(ava => ava.avatarId === avatar.avatarInfo.avatarId)
            return 'https://strapi.arsconnect.com' + temp[0].avatarImage.url
        },
        viewAnalytics(kiosk) {
            console.log('Kiosk: ', kiosk.data())
            this.$store.commit("setSelectKioskAnalytics", kiosk.data().applicationId)
            this.$router.push('/kioskAnalytics')
            this.$store.dispatch('getKioskAnalytics', {
                type: 'Today',
                start: null,
                end: null
            })
        }
    }
}
</script>
<style>
.cardBorderOffline {
    border-left: 6px solid #ff5252 !important;
    border-top: 0.5px solid #bdbdbd !important;
    border-bottom: 0.5px solid #bdbdbd !important;
    border-right: 0.5px solid #bdbdbd !important;
}

.cardBorderOnline {
    background-color: #C5E1A5 !important;
    border-left: 6px solid #4caf50 !important;
    border-top: 0.5px solid #bdbdbd !important;
    border-bottom: 0.5px solid #bdbdbd !important;
    border-right: 0.5px solid #bdbdbd !important;
}

.cardBorderBusy {
    border-left: 6px solid #4caf50 !important;
    border-top: 0.5px solid #bdbdbd !important;
    border-bottom: 0.5px solid #bdbdbd !important;
    border-right: 0.5px solid #bdbdbd !important;
}
</style>